import * as React from 'react';
import { logout } from '../utils/auth';

const isBrowser = typeof window !== 'undefined';

const Logout = (): JSX.Element => {
  if (isBrowser) {
    logout();
  }

  return <p>Logging you out...</p>;
};

export default Logout;
